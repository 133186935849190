
























































































































































































































































































































































































































































































































































































































































































import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import PageTitle from "@/components/General/PageTitle.vue";

@Component({
  components: {
    PageTitle,
  },
})
export default class StaffSupport extends mixins(Navigation) {}
